import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Shelf } from './Shelf.js'

class ShelfScene {
  constructor(domParent) {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('hsl(0, 0%, 94%)');
    let canvasW=window.innerWidth<700 ? 1 : .73;
    this.camera = new THREE.OrthographicCamera( window.innerWidth*canvasW / - 2, window.innerWidth*canvasW / 2, window.innerHeight / 2, window.innerHeight / - 2, 1, 1000 );

    this.renderer = new THREE.WebGLRenderer({antialias:true});
    this.renderer.setSize(window.innerWidth*canvasW, window.innerHeight);
    window.onresize=()=>{
      canvasW=window.innerWidth<700 ? 1 : .73;
      this.camera.left = window.innerWidth*canvasW / - 2;
      this.camera.right = window.innerWidth*canvasW / 2;
      this.camera.top = window.innerHeight / 2;
      this.camera.bottom = - window.innerHeight / 2;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth*canvasW, window.innerHeight);
      const aspect= window.innerWidth/window.innerHeight;
      // console.log(aspect);
      this.render();
    }

    domParent.appendChild(this.renderer.domElement);

    this.shelf=new Shelf();
    this.scene.add(this.shelf.mainGroup);
    this.zoom=300;
    this.targzoom=300;

    this.bb = new THREE.Box3().setFromObject(this.shelf.mainGroup)

    this.camera.position.set(-7,5,10);
    this.camera.lookAt(0,this.shelf.h/2,0);
    this.camera.zoom=this.zoom;
    this.camera.updateProjectionMatrix();
    this.isUpdating=false;


    this.scene.add(new THREE.GridHelper(3,3))
    this.speed=0.01;
    this.zoomTimeout=null;

    this.render();
  }

  setZoom(zoom){
    this.targzoom*=zoom;
    if(!this.isUpdating)
      this.render();

  }

  render() {
    this.camera.lookAt(0,this.shelf.h/3,0);
    this.camera.updateProjectionMatrix();
    // this.f.intersectsObject( this.shelf.mainGroup );
    const max= this.bb.setFromObject(this.shelf.mainGroup).max.multiply(new THREE.Vector3(1,1,-1));
    const p = max.project(this.camera)
    const y = p.y;
    const x = p.x;
    if(y>.95 ){
      this.targzoom=this.zoom*1/(y*1.2)
    }

    this.zoom=lerp(this.zoom,this.targzoom,0.03);
    this.camera.zoom=this.zoom;
    this.camera.updateProjectionMatrix();

    this.isUpdating=false;
    if(Math.abs(this.zoom - this.targzoom)>5) {
      window.requestAnimationFrame(() => this.render());
      this.isUpdating=true;
    }

    this.renderer.render(this.scene, this.camera);
  }

}

function lerp (start, end, amt){
  return (1-amt)*start+amt*end
}

export {ShelfScene}