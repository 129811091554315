import * as THREE from 'three'

class Shelf {
  constructor(w=100,h=200,d=30,floors=3) {
    this.w=w/100;
    this.h=h/100;
    this.d=d/100;

    this.mainGroup=new THREE.Group();
    this.mainGroup.name='Shelf';

    this.cornersGroup=new THREE.Group();
    this.cornersGroup.name='Corners';
    this.mainGroup.add(this.cornersGroup);

    this.floorsGroup=new THREE.Group();
    this.floorsGroup.name='Floors';
    this.mainGroup.add(this.floorsGroup);

    this.corners=[null,null,null,null];
    const geometry = new THREE.BoxGeometry(1, 1, 1)
    const material = new THREE.MeshStandardMaterial({
        side: THREE.FrontSide,
        color: 0,
        wireframe: false
    })
    const coffset=[[-1,1],[1,-1],[1,1],[-1,-1]];
    this.corners.forEach((item, i) => {
      const cube = new THREE.Mesh(geometry, material);
      cube.scale.set(0.025,this.h,0.025);
      cube.position.set(this.d/2*coffset[i][0],this.h/2,this.w/2*coffset[i][1]);
      this.corners[i]=cube;
      this.cornersGroup.add(cube);
    });
    this.floors = [];
    this.setFloors(floors);
  }

  setFloors(count){
    this.floorsGroup.children=[];
    this.floors = [];
    for (var i = 0; i < count; i++) {
      const floor = new Floor(this.w,this.d);
      this.floors.push(floor);
      this.floorsGroup.add(floor.mainGroup);
      const firstFloorAt =this.h*0.05;
      const dist = (this.h-firstFloorAt)/(count-1)
      floor.setPosY(dist*i+firstFloorAt)
    }
  }

  setWidth(w){
    this.w=w/100;
    this.updateSize();
  }
  setHeight(h){
    this.h=h/100;
    this.updateSize();
  }
  setDepth(d){
    this.d=d/100;
    this.updateSize();
  }

  updateSize(){
    const coffset=[[-1,1],[1,-1],[1,1],[-1,-1]];
    this.corners.forEach((item, i) => {
      item.scale.set(0.025,this.h,0.025);
      item.position.set(this.d/2*coffset[i][0],this.h/2,this.w/2*coffset[i][1]);
    });

    this.floors.forEach((item, i) => {
      item.setSize(this.w,this.d);
    });
  }
}

class Floor {
  constructor(w=1,d=.3) {
    this.mainGroup=new THREE.Group();
    this.mainGroup.name = 'Floor';

    this.widths=[null,null];
    const geometry = new THREE.BoxGeometry(1, 1, 1)
    const material = new THREE.MeshStandardMaterial({
        side: THREE.FrontSide,
        color: 0,
        wireframe: false
    })

    this.widths.forEach((item, i) => {
      const cube = new THREE.Mesh(geometry, material);
      cube.scale.set(0.025, 0.025, w);
      cube.position.set((d/2-0.025)*(i==0?1:-1),0,0)
      this.widths[i]=cube;
      this.mainGroup.add(cube);
    });

    this.depths=[null,null];
    this.depths.forEach((item, i) => {
      const cube = new THREE.Mesh(geometry, material);
      cube.scale.set(d, 0.025, 0.025);
      cube.position.set(0,-0.025,(w/2-0.025)*(i==0?1:-1))
      this.depths[i]=cube;
      this.mainGroup.add(cube);
    });
  }

  setPosY(pos){
    this.mainGroup.position.y=pos;
  }

  setSize(w,d){
    this.widths.forEach((item, i) => {
      item.scale.set(0.025, 0.025, w);
      item.position.set(d/2*(i==0?1:-1),0,0)
    });
    this.depths.forEach((item, i) => {
      item.scale.set(d, 0.025, 0.025);
      item.position.set(0,-0.025*0,w/2*(i==0?1:-1))
    });

  }
}

export{ Shelf }