<template>
  <THREECanvas />
</template>

<script>
import THREECanvas from './components/THREECanvas.vue'

export default {
  name: 'App',
  components: {
    THREECanvas
  }
}
</script>

<style>
body{
  padding:0;
  margin:0;
}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
#app{
  display: flex;
  height:100vh;
  width:100vw;
  margin:0;
}
#app > div{
  flex:1;
}
</style>
