<template>
  <div ref="canvas" @click="exitedit">
    <div id="zoom">
      <span @click="zoom(1.1)">(+)</span>
      <span @click="zoom(.9)">(-)</span>
    </div>
  </div>
  <div id="logo">
    <span></span>
    <span>create space where you need it „clamp_25“ is a system that allows you to clamp 25mm
steel tubes together to create structures that are custom made for your space.
you will also get to know a market that is undiscovered by most and has its own beauty.
with the size calculator we reduce the inhibitions as much as possible.</span>
  </div>
  <div id="edit" @click="edit">
    edit
  </div>
  <div class="widget" :class="{ 'show' : editmode}">
    <div class="datainputs">
      Select the size in cm
      <br>
      <br>
      <div class="table-row"  v-for="(dimension,ind) in dimensions">
        <label :for='dimension.name' >{{ dimension.name }}</label>
        <input type="number" v-model.number="dimension.value" :min="dimension.min" :step="dimension.step" :name="dimension.name"> {{ dimension.unit }}
      </div>
    </div>
    <div class="sheet">
      25mm steel tubes <br>
      <span v-for="quantity in quantities">{{ quantity.name }} {{ quantity.quantity }}x {{ quantity.length }}cm <br></span><br>
      <span> Shelves {{ this.dimensions[0].value }}cm x {{ shelvesB }}cm </span><br>
      <span> Overall length: {{ overallLength }}cm </span> <br>
      <span> You will need {{ clamps }} clamps. <a :href="maillink"> Click here to order.</a> </span>

      <p>
        DISCLAIMER<br>
        Find a local buisness that sell steel, its easy to find on Google.Be friendly and don t be shy you will have a good expirience.
        look for 25mm steel tubes, they are often available with different finishes, feel free to choose the one you like most.
        evrything you need to know is on this paper. Good Luck
      </p>
    </div>


  </div>
</template>

<script>
import { ShelfScene } from '../modules/ShelfScene.js'


export default {
    name: 'THREECanvas',
    data() {
      return {
        // scene:null,
        dimensions: [
          { value:120, name:'Width', unit:'cm', step:'10', min:20},
          { value:200, name:'Height', unit:'cm', step:'10', min:20},
          { value:30, name:'Depth', unit:'cm', step:'10', min:5},
          { value:4, name:'Segments', unit:'', step:'1', min:2},
        ],
        editmode:false
      };
    },
    mounted() {
      this.scene=new ShelfScene(this.$refs.canvas);
      this.scene.shelf.setWidth(this.dimensions[0].value);
      this.scene.shelf.setHeight(this.dimensions[1].value);
      this.scene.shelf.setDepth(this.dimensions[2].value);
      this.scene.shelf.setFloors(this.dimensions[3].value);
      this.scene.render();
    },
    methods: {
      zoom(val){
        this.scene.setZoom(val);
      },
      edit(){
        this.editmode=!this.editmode;
      },
      exitedit(){
        this.editmode=false;
      }
    },
    computed: {
      quantities(){
        return [
          {name:'L', quantity:this.dimensions[3].value*2, length:this.dimensions[0].value},
          {name:'B', quantity:this.dimensions[3].value*2, length:this.dimensions[2].value},
          {name:'H', quantity:4, length:this.dimensions[1].value},
        ]
      },
      overallLength(){
        return this.quantities.reduce((length,quantity)=>{
          return length + quantity.quantity*quantity.length;
        },0)
      },
      clamps(){
        return this.dimensions[3].value*8;
      },
      maillink(){
        return 'mailto:test@test.de?subject='+this.clamps+'x clamp_25&body=Dear clamp_25 Team,%0D%0AI would like to order '+this.clamps+' clamps to the following adress:';
      },
      shelvesB(){
        return this.dimensions[2].value-5.4
      }
    },
    watch:{
      dimensions: {
        deep:true,
        handler(val){
          this.scene.shelf.setWidth(val[0].value);
          this.scene.shelf.setHeight(val[1].value);
          this.scene.shelf.setDepth(val[2].value);
          this.scene.shelf.setFloors(val[3].value);
          this.scene.render();
        }
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
 canvas{
   position:fixed;
   top:0;
   left:0;
 }
 body{
   overflow:hidden;
   font-size:1vw;
 }
 .widget{
   z-index:100;
   position:absolute;
   right:0;
   top:0;
   display:flex;
   flex-direction:column;
   overflow:auto;
   border-radius:1em 0 0 1em;
   background-color:white;
   height:100%;
   box-sizing:border-box;
   line-height:1.5;
   text-align:left;
   width:30%;
 }

 .widget input{
   appearance:none;
   border:solid 1px rgb(236, 236, 236);
   padding:.8em;
   border-radius:.8em;
   width:4em;
   text-align:center;
   font-size:inherit;
 }

 .datainputs{
   padding:2em;
   font-size:1.5vw;
 }

 #zoom{
   position:absolute;
   z-index:100;
   left:0;
   bottom:0;
   font-size:1.5vw;
   padding:10px;
   user-select:none;
 }
 #zoom span{
   display:inline-block;
   background-color:white;
   border-radius:100%;
   padding:5px;
   margin: 5px;
 }

 #edit{
   display: none;
 }

 .table-row{
   /* display:table-row; */

 }
 .table-row>*{
   /* display:table-cell; */
   padding:.5em 0;
   margin: .2em 0;
 }
 .table-row>label{
   padding-right:1em;
   width:30%;
   display:inline-block;
 }
 .sheet{
   background-color:rgb(0,255,109);
   padding:2em;
   flex:1;
 }
 .sheet p {
   line-height:1.5;
 }
 a{
   color:black;
 }

 #logo{
   position:absolute;
   width:50%;
   display:flex;
   padding:1em;
 }

 #logo span:first-of-type{
  background-image:url('../../public/img/sun.png');
  flex-basis: 30%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
 }

 #logo span:nth-of-type(2){
  border-left:solid 1px black;
  padding-left: 5px;
  text-align:left;
  margin-left:5px;
  opacity:0;
  pointer-events:none;
 }

 #logo:hover span:nth-of-type(2){
  opacity:0;
 }

 @media (max-width:700px){
   body{
     overflow:hidden;
     font-size:2vw;
   }

   .datainputs, #zoom{
     font-size:3vw;
   }

   #logo{
     width:100%;
   }

   #edit{
     display:block;
     position:absolute;
     background-color:white;
     padding:5px;
     right:0;
     bottom:0;
     margin:15px;
     padding:5px;
     font-size:3vw;
     border-radius:10px
   }
   .widget{
     width:70%;
     transform:translateX(100%);
     transition:transform .3s ease;
   }
   .widget input{
     width: 6em;
   }
   .widget.show{
     transform:translateX(0);
   }
 }

</style>
